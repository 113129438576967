import React, {Component} from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";

import './Tutorial.css';

interface State {
  open: boolean
}

interface Prop {
  finishTutorial(): void,
}

class Tutorial extends Component<Prop, State> {
  state = {
    open: false,
  };

  componentDidMount(): void {

  }

  finishTutorial = () => {
    this.setState({open: false});
    localStorage.setItem('passedTutorial', 'true');
    this.props.finishTutorial();

  };

  render() {
    return (
      <Card className="Card">
        <CardMedia
          className="CardMedia"
          image={process.env.PUBLIC_URL + "/favicon.jpg"}
          title="MudCar. What car."
        />
        <CardContent className="CardContent">
          <Typography variant="h5" component="h2">
            MudCar | What car is this
          </Typography>
          <Typography className="Title" color="textSecondary">
            A car identifier backed by deep learning neural network to recognize different cars. It can identify different car brands and models of different model years, ranging from classic cars in 1960s to newly-released cars in 2019.
          </Typography>
          <br/>
          <Typography variant="body2" component="p" color="textSecondary">
            Simply upload an image, we will tell you what brand, model and model year is that.
          </Typography>
        </CardContent>
        <CardActions className="CardActions">
          <Button variant="contained" color="primary" onClick={this.finishTutorial}>Let's start</Button>
        </CardActions>
      </Card>
    );
  }
}

export default Tutorial;