import React, {Component} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import {Link} from 'react-router-dom';

class MudCarAppBar extends Component<{}> {

  render() {
    return (
      <AppBar position="sticky">
        <Toolbar>
          <Typography variant="h6" color="inherit">
            MudCar
          </Typography>
          <div style={{flexGrow: 1}}/>
          <Link to="/settings"><IconButton color="inherit" aria-label="MoreHoriz"><MoreHoriz/></IconButton></Link>
        </Toolbar>
      </AppBar>

    )
  }
}

export default MudCarAppBar;