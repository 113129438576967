import React, { Component } from 'react';

import DropZone from './DropZone';
import Grid from '@material-ui/core/Grid';
import ReactGA from 'react-ga';

import './App.css';
import PrivacyNoteDialog from "./PrivacyNoteDialog";
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Route, Switch } from 'react-router-dom';

import { theme } from "./MyTheme";
import Settings from "./Settings";
import ModelList from "./ModelList";
import About from "./About";
import Tutorial from "./Tutorial";
import { firebaseSignInAnonymously } from './api';

interface State {
  showTutorial: boolean,
  showPrivacy: boolean,
}

class App extends Component<{}, State> {

  state = {
    showTutorial: false,
    showPrivacy: false,
  };

  initializeReactGA = () => {
    if (process.env.NODE_ENV !== 'development') {
      ReactGA.initialize('UA-135542826-1');
      ReactGA.pageview('/');
    }
  };

  finishTutorial = () => {
    console.log("finishTutorial");
    this.setState({ showTutorial: false });
  };

  acceptPrivacy = () => {
    this.setState({ showPrivacy: false });
  };

  componentWillMount(): void {
    this.initializeReactGA();
  }

  componentDidMount() {
    firebaseSignInAnonymously();

    let passedTutorial = localStorage.getItem('passedTutorial');
    if (passedTutorial === null || passedTutorial === 'false') {
      this.setState({ showTutorial: true });
    } else if (passedTutorial === 'true') {
      this.setState({ showTutorial: false });
    }

    let agreePrivacy = localStorage.getItem('agreePrivacy');
    if (agreePrivacy === null) {
      this.setState({ showPrivacy: true });
    } else {
      this.setState({ showPrivacy: false });
    }
  }



  render() {

    return (
      <MuiThemeProvider theme={theme}>
        <div className="App">
          {this.state.showPrivacy === true && this.state.showTutorial === false &&
            <PrivacyNoteDialog acceptPrivacy={this.acceptPrivacy} />}
          <Grid
            container
            className="AppGrid"
            spacing={0}
            justify="center"
          >
            <Grid item xs={12} sm={8} md={6}>
              {this.state.showTutorial === true && <Tutorial finishTutorial={this.finishTutorial} />}
              {!this.state.showTutorial && !this.state.showPrivacy ?
                [
                  <Switch>
                    <Route path="/about" component={About} />
                    <Route path="/settings" component={Settings} />
                    <Route path="/models" component={ModelList} />
                    <Route path="/" component={DropZone} />
                  </Switch>
                ]
                : null}
            </Grid>
          </Grid>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default App;
