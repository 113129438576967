import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {DialogTitle} from "@material-ui/core";

interface Prop {
  acceptPrivacy(): void,
}

interface State {
  open: boolean
}

class PrivacyNoteDialog extends Component<Prop, State> {
  state = {
    open: false,
  };

  componentDidMount() {
    let agreePrivacy = localStorage.getItem('agreePrivacy');
    if (agreePrivacy == null) {
      this.setState({open: true});
    }
  }

  agree = () => {
    localStorage.setItem('agreePrivacy', 'true');
    this.setState({open: false});
    this.props.acceptPrivacy();
  };

  handleClose = () => {
    this.setState({open: false});
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
        >
          <DialogTitle id="simple-dialog-title">Your Privacy</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              The photo will be uploaded and stored on the server. We will use the images to help re-train and improve the recognition model. The images will NOT be used for other purposes.
            </DialogContentText>
            <DialogContentText>
              Only images you are going to upload will be stored. No other information will be collected from you without your prior consent.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.agree} color="secondary">
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default PrivacyNoteDialog;
