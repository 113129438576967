import React, {Component} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';


interface State {
  checked: boolean
}

class SettingAskForHelpSwitch extends Component<{}, State> {
  state = {
    checked: false,
  };

  componentDidMount(): void {
    let askForHelp = localStorage.getItem('askForHelp');
    if (askForHelp == null || askForHelp === 'true') {
      this.setState({checked: true});
    } else if (askForHelp === 'false') {
      this.setState({checked: false});
    }
  }

  handleToggle = () => {
    if (this.state.checked) {
      this.setState({checked: false});
      localStorage.setItem('askForHelp', 'false');
    } else {
      this.setState({checked: true});
      localStorage.setItem('askForHelp', 'true');
    }
  };


  render() {
    return (
      <ListItem>
        <ListItemText id="switch-list-label-wifi" primary="Help to tell us what model is that after you submitted an image"/>
        <ListItemSecondaryAction>
          <Switch
            onChange={this.handleToggle}
            checked={this.state.checked}
            inputProps={{'aria-labelledby': 'switch-list-label-wifi'}}
          />
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}

export default SettingAskForHelpSwitch;