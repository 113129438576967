import React, {Component} from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import {Link} from 'react-router-dom';
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import SettingAskForHelpSwitch from "./SettingAskForHelpSwitch";

import './Settings.css';

class Settings extends Component {
  render() {
    return (
      <div className={"GridPage"}>
        <AppBar position="sticky">
          <Toolbar>
            <Link to="/">
              <IconButton color="inherit" aria-label="Close">
                <ArrowBackIos/>
              </IconButton>
            </Link>
            <Typography variant="h6" color="inherit">
              More
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          <Link to="/about">
            <ListItem button>
              <ListItemText primary="About"/>
              <ChevronRightIcon/>
            </ListItem>
          </Link>

          <Divider/>

          <Link to="/models">
            <ListItem button>
              <ListItemText primary="List of models"/>
              <ChevronRightIcon/>
            </ListItem>
          </Link>
          <Divider/>
          <SettingAskForHelpSwitch/>
          <Divider/>
        </List>
      </div>
    )
  }
}

export default Settings;