import React, {Component, DragEvent} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MobileDetect from 'mobile-detect';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddPhotoAlternate from '@material-ui/icons/AddPhotoAlternate';

import './DropZone.css';
import AskForHelpSnackbar from "./AskForHelpSnackbar";
import FeedbackDialog from "./FeedbackDialog";
import SubmitFeedbackSnackbar from "./SubmitFeedbackSnackbar";
import NoCarDialog from "./NoCarDialog";
import HttpErrorDialog from "./HttpErrorDialog";

import {getPredictions} from './api';
import loadImage from 'blueimp-load-image';
import MudCarAppBar from "./MudCarAppBar";
import {Divider} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight"

interface State {
  isDragging: boolean,
  isPredicting: boolean,
  expanded: boolean,
  imageDataUrl: string,
  imageFile: File,
  predictionResult: PredictionResult,
  device: string,
  showFeedback: boolean,
  feedbackSubmitted: boolean,
  predictionError: boolean,
  isLoadingImage: boolean,
}

interface Prediction {
  confidence: number,
  label: string,
}

export interface PredictionResult {
  file_name: string;
  predictions: Array<Prediction>
}

class DropZone extends Component<{}, State> {
  md = new MobileDetect(window.navigator.userAgent);
  state = {
    isDragging: false,
    isPredicting: false,
    expanded: false,
    imageDataUrl: "",
    imageFile: new File([""], "filename"),
    predictionResult: {} as PredictionResult,
    device: this.md.mobile(),
    showFeedback: false,
    feedbackSubmitted: false,
    predictionError: false,
    isLoadingImage: false,
  };

  oldImageDataUrl = "";

  tryAgain = (isUseYolo = true) => {
    this.getPrediction(this.state.imageFile, isUseYolo);
  };

  getPrediction = (image: File, isUseYolo: boolean = true) => {
    const formData = new FormData();
    formData.append('image', image);
    this.setState({isPredicting: true, predictionResult: {} as PredictionResult, predictionError: false});
    getPredictions(formData, isUseYolo).then(data => {
      console.log(data);
      this.setState({predictionResult: data, isPredicting: false});
    }, () => {
      this.setState({predictionError: true, isPredicting: false});
    });
  };

  onDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    this.setState({isDragging: false, feedbackSubmitted: false});
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      let imageFile = e.dataTransfer.files[0];
      let fileType = imageFile.type;
      let imageTypes = ['image/png', 'image/gif', 'image/bmp', 'image/jpg', 'image/jpeg'];
      if (imageTypes.includes(fileType)) {
        this.parseOrientation(imageFile);
      } else {
        alert('Please drop an image.');
      }
    }
  };

  onDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    this.setState({isDragging: true});
    this.setState({imageDataUrl: ""});
  };

  onDragLeave = () => {
    this.setState({isDragging: false});
    this.setState({imageDataUrl: this.oldImageDataUrl});
  };

  parseOrientation(imageFile: File) {
    this.setState({imageFile: imageFile});
    this.getPrediction(imageFile);
    loadImage.parseMetaData(imageFile, (data: any) => {
      if (data.exif && data.exif.get('Orientation')) {
        let orientation = data.exif.get('Orientation');
        console.log(orientation);
        this.loadImage(imageFile, orientation);
      } else {
        this.loadImage(imageFile);
      }
    });
  }

  loadImage(imageFile: File, orientation?: number) {
    if (!orientation || orientation == 1) {
      let imageFileDataUrl = window.URL.createObjectURL(imageFile);
      this.setState({imageDataUrl: imageFileDataUrl});
      this.oldImageDataUrl = imageFileDataUrl;
    } else {
      this.setState({isLoadingImage: true});
      let loadImageOptions = {canvas: true, orientation: orientation};
      loadImage(imageFile, (canvas: any) => {
        let imageFileDataUrl = canvas.toDataURL(imageFile.type);
        this.setState({imageDataUrl: imageFileDataUrl, isLoadingImage: false});
        this.oldImageDataUrl = imageFileDataUrl;
      }, loadImageOptions);
    }
  }

  onFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget && e.currentTarget.files && e.currentTarget.files.length > 0) {
      let imageFile = e.currentTarget.files[0];
      this.parseOrientation(imageFile);
    } else {
      return;
    }
  };

  onPaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    let items = e.clipboardData.items;
    for (let i = 0; i < items.length; i ++) {
      let item = items[i];
      let fileType = item.type;
      let imageTypes = ['image/png', 'image/gif', 'image/bmp', 'image/jpg', 'image/jpeg'];
      if (imageTypes.includes(fileType)) {
        let blob = item.getAsFile() as File;
        this.parseOrientation(blob);
        break;
      }
    }
  };

  googleModel = (name: string) => {
    window.open('https://www.google.com/search?q=' + name, '_blank');
    console.log(name);
  };

  _renderListItem() {
    if (this.state.isPredicting == false) {
      if (this.state.predictionResult.predictions) {
        let listItem = [] as Array<any>;
        this.state.predictionResult.predictions.map(result => {
          let confidence = (result.confidence * 100);
          listItem.push(<ListItem button onClick={() => this.googleModel(result.label)}>
            <ListItemText primary={result.label}
                          secondary={confidence < 0.01 ? "< 0.01 %" : confidence.toFixed(2) + " %"}/>
            <ChevronRightIcon/>
          </ListItem>);
          listItem.push(<Divider/>);
        });
        return listItem;
      } else {
        return null;
      }
    } else {
      return <CircularProgress/>
    }
  }

  askForHelpAnswered = () => {
    this.setState({showFeedback: true});
  };

  feedbackDialogOnClose = (submitted: boolean) => {
    this.setState({showFeedback: false});
    console.log(submitted);
    if (submitted == true) {
      this.setState({feedbackSubmitted: true});
    }
  };

  feedbackSnackbarOnClose = () => {
    this.setState({feedbackSubmitted: false})
  };

  render() {
    let ImageIcon;
    let ClickDropLabel;

    if (this.state.imageDataUrl == "" && !this.state.isLoadingImage) {
      ImageIcon = <AddPhotoAlternate className="AddPhotoAlternate"/>;
      if (this.state.isDragging) {
        ClickDropLabel = <div className="ClickDropLabel">Drop it here</div>;
      } else {
        if (this.state.device != null) {
          ClickDropLabel = <div className="ClickDropLabel">Click to Select an Image</div>
        } else {
          ClickDropLabel = <div className="ClickDropLabel">Click to Select an Image or Drag it Here.</div>
        }
      }
    } else {
      ImageIcon = null;
      ClickDropLabel = null;
    }

    return (
      <div className="GridPage" onPaste={this.onPaste}>
        <MudCarAppBar/>
        <div
          className="Container"
          onDragOver={this.onDragOver}
          onDragLeave={this.onDragLeave}
          onDrop={this.onDrop}
        >
          {this.state.predictionResult.predictions && this.state.predictionResult.predictions.length > 0 && this.state.isPredicting == false &&
          <AskForHelpSnackbar askForHelpAnswered={this.askForHelpAnswered}/>}
          {this.state.showFeedback == true ? <FeedbackDialog predictionResult={this.state.predictionResult}
                                                             feedbackDialogOnClose={this.feedbackDialogOnClose}/> : ""}
          {this.state.feedbackSubmitted == true ?
            <SubmitFeedbackSnackbar feedbackSnackbarOnClose={this.feedbackSnackbarOnClose}/> : ""}
          {this.state.predictionResult.predictions && this.state.predictionResult.predictions.length == 0 ?
            <NoCarDialog tryAgain={() => this.tryAgain(false)}/> : ""}
          {this.state.predictionError ? <HttpErrorDialog tryAgain={this.tryAgain}/> : ""}
          <label>
            <input type="file" accept="image/*" onChange={this.onFileSelected} ref="input"/>
            <div className="ImageContainer">
              {ImageIcon}
              {ClickDropLabel}
              {this.state.imageDataUrl != "" && !this.state.isDragging && !this.state.isLoadingImage &&
              <img className="Image" src={this.state.imageDataUrl} alt=""/>}
              {this.state.isLoadingImage && <CircularProgress disableShrink style={{color: 'white'}}/>}
            </div>
          </label>
          <div className="ResultListContainer">
            <List>
              {this._renderListItem()}
            </List>
          </div>

        </div>
      </div>
    );
  }
}

export default DropZone;
