import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

interface State {
  open: boolean
}

interface Prop {
  tryAgain(): void,
}

class NoCarDialog extends Component<Prop, State> {
  state = {
    open: true,
  };

  tryAgain = () => {
    this.props.tryAgain();
    this.handleClose();
  }

  handleClose = () => {
    this.setState({open: false});
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{marginTop: 16}}>
              No car is found in this image.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.tryAgain} color="secondary">
              Identify Anyway
            </Button>
            <Button onClick={this.handleClose} color="secondary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default NoCarDialog;
