import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

interface State {
  open: boolean
}

interface Prop {
  askForHelpAnswered(): void
}

class AskForHelpSnackbar extends React.Component<Prop, State> {
  state = {
    open: false,
  };

  componentDidMount(): void {
    let askForHelp = localStorage.getItem('askForHelp');
    if (askForHelp == null || askForHelp === 'true') {
      this.setState({open: true});
    } else if (askForHelp === 'false') {
      this.setState({open: false});
    }
  }

  answerYes = () => {
    this.props.askForHelpAnswered();
    this.setState({open: false});
  };

  handleClose = (event: React.SyntheticEvent<any>, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({open: false});
  };

  render() {
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.open}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Can you help to tell us what model is it?</span>}
          action={[
            <Button key="undo" color="secondary" size="small" onClick={this.answerYes}>
              Yes!
            </Button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className="CloseButton"
              onClick={this.handleClose}
            >
              <CloseIcon/>
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}


export default AskForHelpSnackbar;