import React, {Component} from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import {Link} from 'react-router-dom';

class About extends Component {
  render() {
    return (
      <div className={"GridPage"}>
        <AppBar position="sticky">
          <Toolbar>
            <Link to="/settings">
              <IconButton color="inherit" aria-label="Close">
                <ArrowBackIos/>
              </IconButton>
            </Link>
            <Typography variant="h6" color="inherit">
              About
            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{textAlign: "justify", padding: 20}}>
          <p>MudCar, i.e. "WhatCar" or "What car is this?", is a deep learning neural network model trained to recognize
            different car models. Not only it can distinguish different brands, but also models of different model
            years, from classic cars in the 60s to newly-released 2019 models.</p>

          <p>We are still at the testing stage. This web app is a sneak peek of what it can do. Currently, the time
            needed for recognition is relatively slow, as we are running on a low-budget server. The speed shall be improved when we are ready to launch.</p>

          <p>We build the image database by ourselves, instead of using the commonly used car dataset (i.e., Standford
            Cars196 and CUHK CompCars)</p>

          <p>We have over 20GB image data of more than 1200 different car models. The number is constantly growing. We
            collect feedback from enthusiasts, investigate and clean the image database.</p>

          <p>Together with the ever-growing images, we re-train the network regularly to provide high quality, accurate
            and up-to-date recognition model.</p>

          <p><a href="https://about.mudcar.dev" target="_blank">See our website to learn more.</a></p>
        </div>

      </div>
    )
  }
}

export default About;