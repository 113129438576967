import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

interface State {
  open: boolean
}

interface Prop {
  feedbackSnackbarOnClose(): void
}

class SubmitFeedbackSnackbar extends React.Component<Prop, State> {
  state = {
    open: true,
  };

  handleClose = () => {
    this.setState({open: false});
    this.props.feedbackSnackbarOnClose();
  };

  render() {
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.open}
          autoHideDuration={3500}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Thank you!</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className="CloseButton"
              onClick={this.handleClose}
            >
              <CloseIcon/>
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}


export default SubmitFeedbackSnackbar;